
import {
    updateProfile,
    getStudentDetailsById,
    getOTPByClassUpdate,
    updateStudentClass,
    updateStudentProfileOfSubscriptionService
} from '../../services/ProfileService';

import {
    logout,
} from '../actions/AuthActions';

import {
    PROFILE_LOADING,
    SET_PROFILE_DATA,
    UPDATE_PROFILES_DETAILS,
    USER_UPDATE_PROFILE_CONFIRMED,
    PROFILE_DATA_UPDATED,
    PROFILE_IMAGE,
    PROFILE_FILE,
    GET_PROFILES_DETAILS,
    RESIZE_MODAL_CLOSE
} from '../constants';

import * as utility from '../../utility/Utility';
import profileTestJeson from '../../jsx/pages/Profile/profileTestJeson.json'

export function getProfileDetailsById(history) {
    return (dispatch) => {
        getStudentDetailsById()
            .then((response) => {
                // console.log("@response----", response)
                // response = profileTestJeson;
                if (response.data.status == 200) {
                    dispatch(getStudentDetailsAction(response.data.data));
                }
                if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                }
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

export function getOTPByClassUpdateData(getClassVerificationDetail,history) {
    return (dispatch) => {
        getOTPByClassUpdate()
            .then((response) => {
                if (response.data.status == 200) {
                    getClassVerificationDetail(response.data);
                }
                if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                }
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

export function updateStudentClassData(class_id,updateStudentCallback,history) {
    return (dispatch) => {
        updateStudentClass(class_id)
            .then((response) => {
                if (response.data.status == 200) {
                    //utility.showSuccess(response.data.msg);
                    updateStudentCallback();
                }
                if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                }
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

export function updateProfileDetails(proImg, fname, lname, dob, email, gender, address, pincode, mobile, standard, board, schoolName, schoolAddress, password, history) {
    return (dispatch) => {
        updateProfile(proImg, fname, lname, dob, email, gender, address, pincode, mobile, standard, board, schoolName, schoolAddress, password)
            .then((response) => {
                // console.log('response...',response.data);
                if (response.data.status == 200) {
                    if (response.data.password_change != 1) {
                        dispatch(updateProfileAction(response.data.data[0]));
                        // dispatch(profileDataUpdatedAction(1));
                        dispatch(userUpdateProfileConfirmedAction(response.data.data[0]));
                        history.push('/dashboard');
                        utility.showSuccess(response.data.msg);
                    } else {
                        utility.showSuccess(response.data.msg);
                        setTimeout(function () {
                            dispatch(logout(history));
                            return;
                        }, 5000);
                    }
                }
                if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                }
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

export function updateStudentProfileOfSubscription(pincode, schoolname, schooladd, callBackFunction, history) {
    return (dispatch) => {
        updateStudentProfileOfSubscriptionService(pincode, schoolname, schooladd)
            .then((response) => {
                if (response.data.status == 200) {
                    callBackFunction(response.data.data[0])
                }
                if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                }
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

export function getStudentDetailsAction(data) {
    return {
        type: GET_PROFILES_DETAILS,
        payload: data,
    };
}

export function updateProfileAction(data) {
    return {
        type: UPDATE_PROFILES_DETAILS,
        payload: data,
    };
}

export function setProfileDataAction(data) {
    return {
        type: SET_PROFILE_DATA,
        payload: data,
    };
}

export function loadingProfile(status) {
    return {
        type: PROFILE_LOADING,
        payload: status,
    };
}

export function userUpdateProfileConfirmedAction(data) {
    return {
        type: USER_UPDATE_PROFILE_CONFIRMED,
        payload: data,
    };
}

export function profileDataUpdatedAction(data) {
    return {
        type: PROFILE_DATA_UPDATED,
        payload: data,
    };
}

export function profileImageUpdatedAction(data) {
    return {
        type: PROFILE_IMAGE,
        payload: data,
    };
}
export function profileImageFileUpdatedAction(data) {
    return {
        type: PROFILE_FILE,
        payload: data,
    };
}